.btnUp {
  bottom: 165px;
}

.container {
  position: relative;
  display: flex;
  min-width: 100%;
  flex-direction: column;
  justify-content: center;
  background-color: var(--color-grey-bg);
  font-size: var(--property-main-layout-text-size);
}

.isAbsolute {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.components {
  position: relative;
  display: flex;
  width: 100%;
  flex-grow: 1;
  justify-content: center;
  margin-top: 68px;
}

.fullScreenHeight {
  min-height: 100vh;
}

.mainPage {
  overflow: hidden;
  flex-direction: column;
  align-items: center;
}
