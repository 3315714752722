

.popupWrapper {
  position: relative;
  display: flex;
  width: 100%;
  margin: 10px 0;
  border-radius: 10px;
  color: var(--color-primary-white);
}

.notificationBG {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid var(--color-base-black);
  background-color: #4a4a4a;
  border-radius: 10px;
  pointer-events: none;
}
