.header {
  position: fixed;
  z-index: 4;
  top: 0;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
}

.nav {
  position: relative;
  z-index: 1;
  display: flex;
  width: 100%;
  height: var(--property-navbar-height);
  justify-content: center;
  padding: 0 15px;
  border-bottom: 1px solid var(--color-grey-2);
  background-color: var(--color-primary-white);
  box-shadow: 0 3px 15px #0088ff0d;
}


.headerContent {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
}

.leftSide {
  display: flex;
  align-items: center;
}

.hamburger {
  display: inline-block;
  overflow: visible;
  border: 0;
  margin: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font: inherit;
  outline: none;
  text-transform: none;
  transition-duration: 0.15s;
  transition-property: opacity, filter;
  transition-timing-function: linear;
}

.hamburgerBox {
  position: relative;
  display: inline-block;
  width: 16px;
  height: 12px;
}

.hamburgerInner {
  top: 50%;
  display: block;
  margin-top: -2px;
  transition-duration: 75ms;
  transition-timing-function: cubic-bezier(0.55, 0.06, 0.68, 0.19);

  &,
  &::after,
  &::before {
    position: absolute;
    display: block;
    width: 16px;
    height: 2px;
    background-color: var(--color-base-black);
    border-radius: 4px;
    content: '';
    transition-duration: 0.15s;
    transition-property: transform;
    transition-timing-function: ease;
  }

  &::before {
    top: -5px;
    transition: top 75ms ease 0.12s, opacity 75ms ease;
  }

  &::after {
    bottom: -5px;
    transition: bottom 75ms ease 0.12s, transform 75ms cubic-bezier(0.55, 0.06, 0.68, 0.19);
  }
}

.hamburgerInnerActive {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);

  &::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 75ms ease, transform 75ms cubic-bezier(0.22, 0.61, 0.36, 1) 0.12s;
  }

  &::before {
    top: 0;
    opacity: 0;
    transition: top 75ms ease, opacity 75ms ease 0.12s;
  }
}

.rightSide {
  display: flex;
  align-items: center;
}

.searchIcon {
  display: none;
}

.notificationPath {
  transition: all 0.5s;
}

.notification {
  position: relative;
  width: 24px;
  height: 24px;
  border: none;
  margin-right: 20px;
  background: transparent;

  &:hover {
    .notificationPath {
      fill: var(--color-primary-100);
    }
  }
}

.notificationAcive {
  .notificationPath {
    fill: var(--color-primary-100);
  }
}

.notificationCount {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 13px;
  height: 13px;
  background: linear-gradient(#f44646, #ff7575);
  border-radius: 50%;
  color: var(--color-primary-white);
  font-size: 8px;
}

.auth {
  display: flex;
  height: 100vh;
  justify-content: center;
  padding-bottom: 40px;
  background: var(--color-primary-white);
}

.popupPush {
  position: absolute;
  right: 5px;
}

.headerLine {
  display: flex;
  width: 100%;
  justify-content: center;
}

.headerContainer {
  position: relative;
  width: 100%;
  max-width: 1440px;
}

.logoMod {
  margin-left: 0px;
}


@media (min-width: 375px) {
  .nav {
    padding: 0 15px;
  }
}

@media (min-width: 768px) {
  .searchIcon {
    display: block;
  }

  .nav {
    padding: 0 20px;
  }

  .popupPush {
    right: 55px;
  }

  .logoMod {
    margin-left: 20px;
  }

}

@media (min-width: 1440px) {
  .headerContent {
    position: relative;
    width: 100%;
  }

  .hamburger {
    display: none;
  }

  .logo {
    width: 179px;
    height: 40px;
    margin-left: 0;

    img {
      width: 179px;
      height: 40px;
    }
  }

  .notification {
    width: 20px;
    height: 25px;
    margin-right: 36px;
  }

  .notificationCount {
    width: 18px;
    height: 18px;
    padding-top: 2px;
    font-size: 10px;
  }

  .popupPush {
    right: 140px;
  }
}

@media (min-width: 1375px) {
  .nav {
    padding: 0 40px;
  }
}

@media (min-width: 1500px) {
  .headerContent {
    padding-right: 0;
    padding-left: 0;
  }
}