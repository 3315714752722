.popupWrapper {
  width: 100%;
  margin: 0 auto;
}

.headerTitle {
  margin-right: 40px;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
}

.outerContainer {
  display: flex;
  max-width: 100%;
  flex-direction: column;
}

.input {
  border-radius: 10px;
  font-weight: 400;
  line-height: 22px;

  &:focus {
    border: 1px var(--color-primary-100) solid;
  }
}

.label {
  color: var(--color-base-80);
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
}

@media (min-width: 768px) {
  .popupWrapper {
    width: auto;
    padding-right: 0;
  }

  .outerContainer {
    flex-wrap: nowrap;
  }
}