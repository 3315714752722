.togglePasswordVisibility {
  position: absolute;
  top: 50%;
  right: 10px;
  display: flex;
  align-items: center;
  padding: 0;
  border: none;
  background-color: rgba(0, 0, 0, 0);
  transform: translateY(-50%);
}

.password {
  font:
    large 'Verdana',
    sans-serif;

  &::placeholder {
    font-family: 'Manrope', sans-serif;
    font-weight: 400;
  }
}

.inputMod {
  padding-right: 40px;
}

.hidden {
  display: none;
}
