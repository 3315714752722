.popupHelper {
  animation: animation-fade 0.3s linear;
}

.loaderMod {
  width: 100px;
  height: 100px;
}

.loaderWrapper {
  display: flex;
  width: auto;
  height: 300px;
  align-items: center;
  justify-content: center;
}


@media (min-width: 768px) {
  .popupHelper {
    width: max-content !important;
    height: max-content !important;
  }

  .loaderWrapper {
    width: 420px;
    height: 444px;
  }

  .loaderWrapperStudents {
    display: flex;
    width: 625px;
    height: 635px;
    align-items: center;
    justify-content: center;
  }

}

@keyframes animation-fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}