.inputWrapper {
  position: relative;
  width: 100%;
}

.input {
  width: 100%;
  height: 46px;
  padding: 5px 0;
  padding-right: 20px;
  padding-left: 14px;
  border: 1px solid var(--color-base-20);
  border-radius: var(--property-border-radius);
  font-size: 16px;
  outline: none;

  &::-webkit-input-placeholder {
    color: var(--color-base-60);
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  }

  &:focus {
    border: 1px solid var(--color-primary-100);
  }
}

.inputMaxLength {
  padding-right: 65px;
}

.counter {
  position: absolute;
  right: 12px;
  bottom: 12px;
  color: #9F9F9F;
  font-size: 12px;
  line-height: 12px;
}

.counterError {
  color: var(--color-error-100)
}

.input.error {
  border: 1px solid var(--color-error-100);
}

.innerContainer {
  width: 100%;
  max-width: 100%;
  margin-right: 0;
}

.labelInput {
  position: relative;
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
}

.dynamicName {
  display: inline-block;
  width: 100%;

  .outerName {
    position: absolute;
    z-index: 1;
    top: 14px;
    left: 14px;
    min-width: 200px;
    color: var(--color-base-60);
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    opacity: 0.4;
    transition: 0.5s;

    &.active {
      top: 5px;
      font-size: 10px;
      font-weight: 400;
      line-height: 14px;
    }

    &.disabled {
      display: none;
      opacity: 0.4;
    }
  }

  .input {
    padding-top: 15px;
  }
}

.outerName {
  margin-bottom: 8px;
  color: #686868;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;

  &.disabled {
    display: none;
  }
}

.errorCheck {
  height: 1px;
  color: var(--color-error-100);
  font-size: 12px;

  transition: all 0.2s linear;
  transition-timing-function: linear;

  &.active {
    height: 12px;
    margin-top: 4px;
    line-height: 12px;
  }
}

.capitalized {
  text-transform: capitalize;

  &::placeholder {
    text-transform: none;
  }
}

.tolowercase {
  text-transform: lowercase;

  &::placeholder {
    text-transform: none;
  }
}

@media (min-width: 768px) {
  .innerContainer {
    width: 100%;
  }
}