.navWrapper {
  position: absolute;
  top: 68px;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
}

.list {
  position: absolute;
  top: 68px;
  left: 0;
  display: none;
  width: 100%;
  border: 1px solid var(--color-grey-2);
  background-color: var(--color-primary-white);
  list-style: none;
}

.listActive {
  z-index: 1;
  display: block;
}

.listItem {
  position: relative;
  display: flex;
  align-items: center;
  padding-top: 17px;
  padding-bottom: 17px;
  padding-left: 15px;
  border-bottom: 1px solid var(--color-base-20);
  background-color: var(--color-primary-white);
  font-size: 16px;
  font-weight: 500;
  transition: background-color 0.5s;
  white-space: pre;
}

.listElement:last-child {
  .listItem {
    border-bottom: none;
  }
}

.listItemActive {
  background-color: #eaf3fa;
  color: var(--color-primary-100);
  font-weight: 700;
}

.new {
  padding: 6px 12px;
  margin-left: 8px;
  background-color: #19b5ff1a;
  border-radius: 99px;
  color: var(--color-primary-100);
  font-size: 12px;
}

.counter {
  margin-left: auto;
}

@media (min-width: 768px) {
  .searchField {
    display: none;
  }

  .listItem {
    padding-left: 20px;
  }
}

@media (min-width: 1440px) {
  .navWrapper {
    display: none;
  }
  .list {
    position: static;
    display: flex;
    max-width: 960px;
    flex-grow: 1;
    justify-content: center;
    border: none;
    margin: 0 10px;
  }

  .listItem {
    display: flex;
    height: 100%;
    align-items: center;
    padding: 9px 20px;
    border-bottom: none;
    margin-right: 42px;
    border-radius: 10px;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }

    &::after {
      content: none;
    }

    &:hover {
      background-color: #f3f6fd;
    }
  }

  .listItemNew {
    padding: 6px 20px;
  }

  .listItemActive {
    padding: 9px 20px;
  }
}

@media (min-width: 1024px) and (-ms-high-contrast: active) and (-ms-high-contrast: none) {
  .list {
    width: 80%;
  }
}

@media (min-width: 1440px) {
  .list {
    max-width: 1110px;
  }

  .new {
    display: inline-block;
  }
}

@media (min-width: 1500px) {
  .listElement {
    margin: 0 5px;
  }
}

@media (min-width: 1600px) {
  .listElement {
    margin: 0 10px;
  }
}

@media (min-width: 1600px) {
  .listElement {
    margin: 0 15px;
  }
}
