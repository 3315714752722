

.popupWrapper {
  flex-direction: row;
  align-items: center;
  padding: 10px;
  color: var(--color-primary-white);
  font-size: 14px;
  line-height: 19px;
}

.alertError {
  border-color: #ff8d8d;
}

.bgError {
  background-color: #ffbebd;
}

.text {
  position: relative;
  padding-left: 18px;
}

.img {
  position: relative;
}

@media (min-width: 768px) {
  .popupWrapper {
    padding: 20px;
  }
}
