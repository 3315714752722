.main {
  position: relative;
  z-index: 2;
  overflow: visible;
  width: 100%;
  padding: 20px 15px 60px 15px;
}

.inner {
  width: 100%;
}

@media (min-width: 768px) {
  .inner {
    max-width: 690px;
    margin: 0 auto;
  }
}

@media (min-width: 1280px) {
  .main {
    padding: 40px 40px 130px 40px;
  }

  .inner {
    max-width: 1110px;
  }
}

.noTopPadding {
  padding-top: 0;
}

.noBottomPadding {
  padding-bottom: 0;
}

.noLeftPadding {
  padding-left: 0;
}

.noRightPadding {
  padding-right: 0;
}

.fullHeight {
  position: relative;
  min-height: 100vh;
}