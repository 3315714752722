.wrapper {
  width: 100%;
}
.createAcc {
  font-size: 14px;
  line-height: 19px;
}

.topTitle {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 600;
}
.nameRowStyle {
  margin-bottom: 8px;
  color: #686868;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.registerContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
  .title {
    margin-right: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
  }
}

.jumpLogin {
  display: block;
  color: var(--color-primary-100);
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
}

.form {
  .outerContainer {
    display: flex;
    max-width: 100%;
    flex-direction: column;
  }

  .outercheckRemember {
    display: flex;
    align-items: center;
    margin-top: 40px;
  }

  .checkRemember {
    width: 18px;
    height: 18px;
    border: 1px solid var(--color-base-black);
    background: var(--color-primary-white);
    border-radius: 4px;
  }

  .checkRemember.active {
    width: 18px;
    height: 18px;
    background: var(--color-primary-100) 0% 0% no-repeat padding-box;
  }

  .logInContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    gap: 12px;
  }

  .logInRemember {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .logInRememberText {
    color: var(--color-primary-100);
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
  }

  .innerInput {
    margin-bottom: 24px;
  }

  .button {
    width: 100%;
  }

  .errorResponse {
    margin-top: 10px;
    color: var(--color-error-100);
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
  }
}

.studentsCaption {
  margin-bottom: 30px;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}

@media (min-width: 576px) {
  .registerContainer {
    margin-top: 30px;
    .title {
      margin-right: 12px;
    }
  }

  .form {
    .logInRemember {
      width: fit-content;
      justify-content: flex-start;
      margin-top: 0;
    }
  }

  .buttonMaxWidth {
    min-width: 100px;
  }
}

@media (min-width: 768px) {
  .wrapper {
    width: 440px;
  }
  .form {
    .outerContainer {
      flex-wrap: nowrap;
    }
  }
  .studentsCaption {
    margin-bottom: 40px;
  }
}
