.popupWrapper {
  position: fixed;
  z-index: 4;
  top: 67px;
  display: flex;
  width: 100vw;
  min-height: calc(100vh - 67px);
  align-items: center;
  justify-content: center;
}

.popupBG {
  position: fixed;
  z-index: 100001;
  top: 67px;
  left: 0;
  display: flex;
  width: 100%;
  height: calc(100vh - 67px);
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
}

.popupBlock {
  position: relative;
  z-index: 100002;
  overflow: hidden;
  max-width: 800px;
  padding: 20px 15px;
  background-color: var(--color-primary-white);
  border-radius: 20px;
}

.btnClose {
  position: absolute;
  z-index: 101;
  top: 0;
  right: 0;
  width: 46px;
  height: 46px;
  padding: 15px;
  cursor: pointer;

  path {
    width: 16px;
    height: 16px;
    fill: #000;
  }

  :global {
    .injected-svg {
      width: 16px;
      height: 16px;
    }
  }
}

.fullScreens {
  width: 100%;
  height: calc(100vh - 67px);
  border-top: 1px solid #ebebeb;
  border-radius: 0px;
  overflow-y: auto;
  transform: none;
}

.bottomScreens {
  position: fixed;
  top: auto;
  bottom: 0px;
  left: 0px;
  overflow: hidden;
  height: initial !important;
  border-radius: 20px 20px 0px 0px;
}

@media (min-width: 768px) {
  .popupBlock {
    width: 80%;
    padding: 30px;
  }

  .fullScreens {
    position: relative;
    border-radius: 20px;
    overflow-y: hidden;
  }

  .bottomScreens {
    align-self: center;
    border-radius: 20px;
  }
}

@media (min-width: 1024px) {
  .popupBlock {
    width: 50%;
  }
}