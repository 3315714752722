.banner {
    position: sticky;
    z-index: 2;
    bottom: 0;
    background: #535353;
    color: var(--color-primary-white);
}

.wrapper {
    position: relative;
    height: auto;
    padding: 17px 15px;
}

.content {
    position: relative;
    display: flex;
    flex-direction: column;

    justify-content: center;
}

.image {
    display: none;
}

.txt {
    width: 85%;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
}

.btn {
    display: flex;
    height: 48px;
    align-items: center;
    justify-content: center;
}

.btnWrapper {
    width: 100%;
}

.btnCloseWrapper {
    position: absolute;
    top: 0;
    right: 0;
}

.btnClose {
    width: 13px;
    height: 13px;
    cursor: pointer;
    fill: #ffffff;
}

@media (min-width: 768px) {
    .btn {
        font-size: 16px;
    }
}

@media (min-width: 1280px) {
    .content {
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }

    .image {
        position: absolute;
        bottom: -10px;
        left: 0;
        display: block;
        width: 143px;
        height: 130px;
    }

    .txt {
        max-width: 510px;
        margin: 0 71px 0 176px;
        font-size: 24px;
        line-height: 33px;
    }

    .btn {
        height: 60px;
    }

    .btnWrapper {
        width: 290px;
        margin-right: 29px;
    }
}