.searchInputWrapper {
  position: relative;
  width: 100%;
  height: 60px;
  box-shadow: 0 3px 5px #0088ff0d;
}

.searchInput {
  width: 100%;
  height: 100%;
  padding-left: 15px;
  border: none;
  background-color: var(--color-grey-bg);
  font-size: 16px;
  font-weight: 600;
  outline: none;
}

.btnSearch {
  position: absolute;
  top: calc(50% - 8px);
  right: 25px;
  width: 16px;
  height: 14px;
}

.btnSearchArrow {
  display: none;
}

@media (min-width: 768px) {
  .searchInput {
    padding-left: 20px;
  }

  .btnSearchLupa {
    display: none;
  }

  .btnSearchArrow {
    display: block;
  }

  .searchInputWrapper {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 25%;
    width: 40%;
    height: 67px;
  }
}

@media (min-width: 1400px) {
  .searchInputWrapper {
    left: 17%;
    width: 60%;
  }
}

@media (min-width: 1535px) {
  .searchInputWrapper {
    left: 15.5%;
    width: 64%;
  }
}
