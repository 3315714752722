.popupHeader {
  color: var(--color-base-black);
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
}

.content {
  margin-top: 15px;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 22px;
}

.firstParagraph {
  padding-bottom: 20px;
}

.button {
  overflow: hidden;
  border-radius: 20px;
}

.forum button {
  border: none;
  background-color: #FF703D;
  border-radius: 200px;
  transition: all 0.3s;

  &:hover {
    background-color: #ff865b;
  }
}



@media (min-width: 768px) {
  .content {
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .popup {
    max-width: 500px;

  }
}