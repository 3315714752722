.contentWrapper {
  position: fixed;
  z-index: 10;
  bottom: 4px;
  left: 50%;
  display: flex;
  width: calc(100% - 8px);
  max-width: 416px;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  margin: 0;
  background-color: #303030;
  border-radius: 10px;
  color: var(--color-primary-white);
  font-size: 12px;
  line-height: 16px;
  transform: translateX(-50%);
}

.link {
  color: inherit;
  text-decoration: underline;
}

.btn {
  width: 100%;
  padding: 10px 24px;
  border: 0;
  margin-left: 10px;
  background-color: #606060;
  border-radius: 6px;
  color: inherit;
  cursor: pointer;
  font: inherit;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  -webkit-tap-highlight-color: transparent;
}

.altPosition {
  bottom: 90px;
}

@media (min-width: 768px) {
  .altPosition {
    bottom: 4px;
  }
  .btn {
    margin-left: 35px;
  }
}

@media (min-width: 1024px) {
  .contentWrapper {
    bottom: 24px;
    left: 24px;
    transform: none;
  }
}
