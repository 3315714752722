.button {
  width: 100%;
  min-width: 140px;
  height: 48px;
  flex-shrink: 0;
  padding: 0 22px;
  border: 1px solid var(--color-primary-100);
  background-color: var(--color-primary-100);
  border-radius: 10px;
  color: var(--color-primary-white);
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.28px;
  line-height: 19px;
  outline: none;
  transition: all 0.5s;

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.buttonSecondary {
  background-color: var(--color-primary-white);
  color: var(--color-primary-100);

  &:disabled {
    border: 1px solid var(--color-base-40);
    color: var(--color-base-40);
    opacity: 1;
  }
}

.buttonThird {
  background-color: transparent;
}

.buttonFourth {
  color: var(--color-primary-100);
}

.buttonFifth {
  border-color: #e8f8ff;
  background-color: transparent;
  color: #e8f8ff;
}

.buttonBlack {
  min-width: 170px;
  padding: 0;
  border-color: var(--color-base-40);
  background-color: transparent;
  color: var(--color-base-black);
  font-weight: 600;
}

.buttonBlackSecond {
  border-color: var(--color-base-black);
  background-color: var(--color-base-black);
  color: var(--color-primary-white);
}

.buttonGrey {
  display: flex;
  min-width: 170px;
  align-items: center;
  justify-content: center;
  padding: 14px 18px;
  border-color: var(--color-base-40);
  background-color: transparent;
  color: var(--color-base-black);
  font-weight: 600;

  &:active {
    background-color: var(--color-base-20);
  }

  &:disabled {
    border-color: var(--color-grey-dark-disabled);
    color: var(--color-grey-dark-disabled);
  }
}

.buttonWhite {
  padding: 0;
  border-color: transparent;
  background-color: var(--color-primary-white);
  color: var(--color-base-black);
}

.buttonWhiteNoHover {
  padding: 0;
  border-color: transparent;
  background-color: var(--color-primary-white);
  color: #171b6d;
}

.button.buttonPurple {
  border-color: var(--color-lavender-100);
  background-color: var(--color-lavender-100);
}

.buttonCongress {
  border-color: transparent;
  background-color: var(--color-bright-blue-2);
  color: var(--color-primary-white);
}

.buttonStudents,
.buttonStudentsBlue,
.buttonStudentsNoHover {
  width: 100%;
  height: unset;
  padding: 20px;
  border-color: transparent;
  border-radius: 0;
  color: var(--color-primary-white);
  font-size: 16px;
  font-weight: 500;
  transition-duration: 0.3s;

  span {
    color: var(--color-students-green);
  }
}

$buttonStudents-gradient: linear-gradient(15deg, black 50%, #74ca54b3);
$buttonStudentsBlue-gradient: linear-gradient(15deg, #5878f6 50%, #74ca54b3);

.buttonStudents {
  background: $buttonStudents-gradient;
  background-color: var(--color-base-black);
  background-position-x: 5%;
  background-size: 200% 100%;
}

.buttonStudentsBlue {
  background: $buttonStudentsBlue-gradient;
  background-color: var(--color-students-primary);
  background-position-x: 5%;
  background-size: 200% 100%;
}

.buttonStudentsNoHover {
  background-color: var(--color-base-black);
}

.buttonStudentsSecondary {
  height: unset;
  padding: 10px 24px;
  border: 1px solid var(--color-base-black);
  background-color: transparent;
  border-radius: 0;
  color: var(--color-base-black);
  font-size: 16px;
  font-weight: 500;
}

.buttonYellow {
  border: 2px solid var(--orange, #ffb400);
  background: var(--orange, #ffb400);
  color: var(--color-base-black);
}

@media (hover) {
  .button:hover {
    border-color: var(--color-primary-80);
    background-color: var(--color-primary-80);
  }

  .button:active {
    border-color: var(--color-primary-60);
    background-color: var(--color-primary-60);
  }

  .buttonSecondary:hover {
    border-color: var(--color-primary-100);
    background-color: #e8f8ff;
  }

  .buttonFourth:hover {
    border-color: var(--color-primary-100);
    background-color: #def4ff;
  }

  .buttonFifth:hover {
    border-color: #e8f8ff;
    background-color: #e8f8ff;
    color: #535353;
  }

  .buttonThird:hover {
    background-color: transparent;
  }

  .buttonBlack:hover {
    border-color: var(--color-base-black);
    background-color: transparent;
  }

  .buttonBlackSecond:hover {
    border-color: var(--color-base-black);
    background-color: transparent;
    color: var(--color-base-black);
  }

  .buttonGrey:hover {
    border-color: var(--color-grey-dark-2);
    background-color: var(--color-grey-bg);
  }

  .buttonWhite:hover {
    border-color: var(--color-base-20);
    background-color: rgba(var(--color-primary-white), 0.7);
  }

  .buttonPurple:hover {
    border-color: #5d6dfc;
    background-color: #5d6dfc;
  }

  .buttonCongress:hover {
    border-color: transparent;
    background-color: #4972fd;
  }

  .buttonStudents:hover {
    border-color: transparent;
    background: $buttonStudents-gradient;
    background-color: var(--color-base-black);
    background-position-x: 65%;
    background-size: 200% 100%;
  }

  .buttonStudentsBlue:hover {
    border-color: transparent;
    background: $buttonStudentsBlue-gradient;
    background-color: var(--color-students-primary);
    background-position-x: 65%;
    background-size: 200% 100%;
  }

  .buttonStudentsSecondary:hover {
    border: 1px solid var(--color-base-black);
    background-color: var(--color-base-black);
    color: var(--color-primary-white);
  }

  .buttonStudentsNoHover:hover {
    border-color: transparent;
    background-color: var(--color-base-black);
  }

  .buttonWhiteNoHover:hover {
    border-color: transparent;
    background: var(--color-primary-white);
  }

  .buttonYellow:hover {
    border: 2px solid var(--orange, #f90);
    background: var(--orange, #f90);
  }
}

@media (min-width: 576px) {

  .buttonStudents,
  .buttonStudentsBlue,
  .buttonStudentsNoHover {
    width: unset;
  }
}

@media (min-width: 1024px) {
  .buttonBlack {
    border-color: var(--color-base-40);
    color: var(--color-base-black);
  }
}

@media (min-width: 1280px) {

  .buttonStudents,
  .buttonStudentsNoHover {
    padding: 20px 40px;
  }
}