.wrapper {
  position: fixed;
  z-index: 4;
  right: 15px;
  display: flex;
  width: calc(100% - 30px);

  flex-direction: column;
}

.message {
  color: #ff47af8f;
  font-size: 24px;
  font-weight: 800;
}

.msgEnter {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}

.msgEnterActive {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: all 400ms;
}

.msgExit {
  opacity: 1;
}

.msgExitActive {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
  transition: all 400ms;
}


@media (min-width: 576px) {
  .wrapper {
    width: 350px;
    max-width: 92%;
  }
}

@media (min-width: 1280px) {
  .wrapper {
    right: 40px;
  }
}