

.search {
  width: 24px;
  height: auto;
  padding-top: 3px;
  border: none;
  margin-right: 16px;
  background: transparent;

  &:hover {
    .searchPath {
      fill: var(--color-primary-100);
    }
    .searchCircle {
      stroke: var(--color-primary-100);
    }
  }
}

.searchPath,
.searchCircle {
  transition: all 0.5s;
}

.searchIsActive {
  .searchPath {
    fill: var(--color-primary-100);
  }

  .searchCircle {
    stroke: var(--color-primary-100);
  }
}

@media (min-width: 768px) {
  .search {
    width: 25px;
    height: 25px;
    margin-right: 30px;
  }
}

@media (min-width: 1280px) {
  .search {
    margin-right: 30px;
  }
}
