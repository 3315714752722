.loadMessage {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.text {
  margin-left: 20px;
}
