.popupWrapper {
  top: 8px;
  flex-direction: row;
  align-items: center;
  color: var(--color-primary-white);
  font-size: 14px;
  line-height: 19px;
}

.alertError {
  border-color: #ff8d8d;
}

.bgError {
  background-color: #ffbebd;
}

.notificationBG {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 10px;
  background-color: #4A4A4AF2;
  border-radius: 10px;
}

.textWrap {
  display: flex;
  flex-direction: column;
}

.title {
  margin-bottom: 4px;
  color: var(--color-primary-white);
  font-weight: 600;
}

.text {
  color: var(--color-primary-white);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.successSign {
  position: relative;
  display: flex;
  width: 30px;
  min-width: 30px;
  height: 30px;
  min-height: 30px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  background: linear-gradient(180deg, #C0F194 0%, #8AB861 100%);
  border-radius: 100%;
}

.successLine {
  position: absolute;
  top: 8px;
  left: 16px;
  width: 2px;
  height: 14px;
  background-color: var(--color-primary-white);
  border-radius: 1px;
  transform: rotate(45deg);
}

.successDot {
  position: absolute;
  top: 14px;
  left: 9px;
  width: 2px;
  height: 7px;
  background-color: var(--color-primary-white);
  border-radius: 1px;
  transform: rotate(-54deg);
}

.warningSign {
  display: flex;
  width: 30px;
  min-width: 30px;
  height: 30px;
  min-height: 30px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  background: linear-gradient(180deg, #FF8888 0%, var(--color-error-100) 100%);
  border-radius: 100%;
}

.warningLine {
  width: 2px;
  height: 10px;
  margin-bottom: 2px;
  background-color: var(--color-primary-white);
}

.warningDot {
  width: 2px;
  height: 2px;
  background-color: var(--color-primary-white);
}

@media (min-width: 768px) {
  .popupWrapper {
    width: 416px;
  }

  .notificationBG {
    padding: 20px;
  }

  .title {
    margin-bottom: 10px;
    color: var(--color-primary-white);
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  }

  .text {
    color: var(--color-primary-white);
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  }

  .successSign {
    margin-right: 15px;
  }

  .warningSign {
    margin-right: 15px;
  }
}